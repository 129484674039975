const host = process.env.REACT_APP_HOST || "";

export interface ServerResponse<T> {
  status: string;
  result: T;
  results: T;
}
export const APIPaths = {
  GET_ACTIVITY: "{{host}}/appV3/api/v3/activity/{{id}}",
  GET_ACTIVITY_Edit: "{{host}}/appV3/api/v3/fetchActivityForEdit/{{id}}",
  AI_ACTIVITY: "{{host}}/appV3/api/v3/requestActivityFromChatGpt",
  GET_LOCATION: "{{host}}/appV3/api/v3/location/{{text}}",
  CREATE: "{{host}}/appV3/api/v3/createActivity",
  EDIT: "{{host}}/appV3/api/v3/updateActivity",
  APP_STATUS: "{{host}}/appV3/api/v3/getAppStatus",
  GET_ATTENDEES: "{{host}}/appV3/api/v3/attendees/{{id}}",
  ATTEND_PAY_SECRETS: "{{host}}/appV3/api/v3/attendOnDirectPay",
  SIGN_UP: "{{host}}/appV3/api/v3/createDoer",
  STRIPE_STATUS: "{{host}}/appV3/api/v3/getAccountStatus",
  GET_DOER: "{{host}}/appV3/api/v3/doer/doerId/{{id}}",
  CHECK_DOER: "{{host}}/appV3/api/v3/doer",
  GET_FRIENDS: "{{host}}/appV3/api/v3/friends/{{id}}",
  GET_WALLETS: "{{host}}/appV3/api/v3/wallet",
  GET_POST: "{{host}}/appV3/api/v3/getPostDetailsByActivityId/{{id}}",
  ADD_COMMENT: "{{host}}/appV3/api/v3/addReactionsNotif",
  GET_EMOTION_USERS: "{{host}}/appV3/api/v3/activityDoersEmotion",
  REACTIONS: "{{host}}/appV3/api/v3/reactions/{{id}}",
  GET_ALL_COMMENTS: "{{host}}/appV3/api/v3/reactionsPagination",
  TICKET_REPORT: "{{host}}/appV3/api/v3/ticketsReport/{{id}}",
  REQUEST_FRIEND: "{{host}}/appV3/api/v3/friend/request/{{id}}",
  GET_FEED: "{{host}}/appV3/api/v3/explore/activities",
  SEARCH_ACTIVITY: "{{host}}/appV3/api/v3/activitySearchAlgolia",
  GET_EMOTIONS: "{{host}}/appV3/api/v3/activityDoersEmotion",
  UNLIKE: "{{host}}/appV3/api/v3/reaction/{{id}}",
  SET_DOER_EMOTION: "{{host}}/appV3/api/v3/setActivityDoerEmotion",
  GOOGLE_CREATE_DOER: "{{host}}/appV3/api/v3/createGoogleUser",
  ATTEND_FOR_FREE: "{{host}}/appV3/api/v3/attendOnDirectPay",
  CHECK_PROMO: "{{host}}/appV3/api/v3/verifyPromocode",
  GET_GIPHY: "{{host}}/appV3/api/v3/getGiphyImage/{{text}}",
  SPONSOR_EXPLORE: "{{host}}/appV3/api/v3/sponsor/activities",
  SPONSOR_UP_ACTIVITIES:
    "{{host}}/appV3/api/v3/sponsor/fetchUpCommingActivities",
  SPONSOR_PAST_ACTIVITIES: "{{host}}/appV3/api/v3/sponsor/fetchPastActivities",
  SPONSOR_Activity: "{{host}}/appV3/api/v3/sponsor/activity/{{id}}",
  REQUEST_ACTIVITY_FROM_LINK: "{{host}}/appV3/api/v3/requestActivityFromLink",
  UPDATE_SPONSOR_PROFILE: "{{host}}/appV3/api/v3/updateSponsorProfile",
  UPDATE_SPONSOR_ORGANAZATION:
    "{{host}}/appV3/api/v3/sponsor/updateOrganization",
  File_Upload: "{{host}}/appV3/api/v3/fileupload",
  SPONSOR_DIRECT_PAY: "{{host}}/appV3/api/v3/sponsor/sponsorDirectPay",
  PROXY: "{{host}}/appV3/api/v3/proxy",
  GET_Web_DOER: "{{host}}/appV3/api/v3/WebDoer",
  CREATE_COMMUNITY: "{{host}}/appV3/api/v3/community",
  EDIT_COMMUNITY: "{{host}}/appV3/api/v3/community/{{id}}",
  GET_MY_COMMUNITY: "{{host}}/appV3/api/v3/community/myCommunities",
  SEARCH_COMMUNITY: "{{host}}/appV3/api/v3/community/search",
  PAGINATION_COMMUNITY: "{{host}}/appV3/api/v3/community/pagination",
  COMMUNITY: "{{host}}/appV3/api/v3/community/{{id}}",
  COMMUNITY_PAST_ACTIVITIES:
    "{{host}}/appV3/api/v3/community/fetchPastActivities",
  COMMUNITY_UP_ACTIVITIES:
    "{{host}}/appV3/api/v3/community/fetchUpCommingActivities",
  COMMUNITY_JOIN: "{{host}}/appV3/api/v3/community/join/{{id}}",
  COMMUNITY_LEFT: "{{host}}/appV3/api/v3/community/left/{{id}}",
  COMMUNITY_MEMBERS: "{{host}}/appV3/api/v3/community/users",
  COMMUNITY_MODERATORS: "{{host}}/appV3/api/v3/community/moderators/{{id}}",
  COMMUNITY_POSTS:
    "{{host}}/appV3/api/v3/community/getPostDetailsByCommunityId/{{id}}",
  GET_INVITATIONS: "{{host}}/appV3/api/v3/activity/GetInvitations",
  SEND_INVITATIONS: "{{host}}/appV3/api/v3/activity/SendInvitation",
  SET_TIMEZONE: "{{host}}/appV3/api/v3/doer/setTimeZone",
  GET_UPCOMING_ACTIVITIES:
    "{{host}}/appV3/api/v3/doer/fetchUpCommingActivities",
  GET_PAST_ACTIVITIES: "{{host}}/appV3/api/v3/doer/fetchPastActivities",
};

export default function getAPIPath(path: string) {
  let url = path.replace("{{host}}", host);
  return url;
}
