export function HostedIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22095 4.63974C8.66112 3.61267 10.1015 3.57034 10.6013 4.56979L12.1677 7.70261C12.214 7.79523 12.3266 7.83277 12.4192 7.78646L13.7901 7.101C14.8571 6.56753 16.0249 7.61885 15.606 8.73579L14.0625 12.852V13.875C14.0625 14.5999 13.4748 15.1875 12.75 15.1875H6.37497C5.65009 15.1875 5.06247 14.5999 5.06247 13.875V12.8676L3.26925 8.76888C2.78684 7.66622 3.91914 6.54991 5.01483 7.04795L6.65995 7.79573C6.75567 7.83924 6.86846 7.79554 6.90988 7.6989L8.22095 4.63974ZM6.18747 13.3125V13.875C6.18747 13.9785 6.27141 14.0625 6.37497 14.0625H12.75C12.8535 14.0625 12.9375 13.9785 12.9375 13.875V13.3125H6.18747ZM13.1102 12.1875L14.5527 8.34077C14.6125 8.18121 14.4457 8.03102 14.2933 8.10723L12.9223 8.79269C12.274 9.11686 11.4856 8.85407 11.1614 8.20572L9.59503 5.0729C9.52364 4.93012 9.31787 4.93617 9.25499 5.08289L7.94392 8.14206C7.65398 8.81858 6.86449 9.12447 6.19442 8.81989L4.5493 8.07211C4.39277 8.00096 4.23102 8.16044 4.29993 8.31796L5.99285 12.1875H13.1102Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 8.4375C9.68566 8.4375 9.9375 8.68934 9.9375 9V10.875C9.9375 11.1857 9.68566 11.4375 9.375 11.4375C9.06434 11.4375 8.8125 11.1857 8.8125 10.875V9C8.8125 8.68934 9.06434 8.4375 9.375 8.4375Z"
        fill="white"
      />
    </svg>
  );
}
