export function SafetyPinIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5911 10.8938C4.12664 9.42937 4.12664 7.055 5.5911 5.59054C7.05557 4.12607 9.42994 4.12607 10.8944 5.59054L13.6569 8.35301C13.9498 8.6459 13.9498 9.12077 13.6569 9.41367C13.364 9.70656 12.8891 9.70656 12.5962 9.41367L9.83374 6.6512C8.95506 5.77252 7.53044 5.77252 6.65176 6.6512C5.77308 7.52988 5.77308 8.9545 6.65176 9.83318L9.41423 12.5956C9.70713 12.8885 9.70713 13.3634 9.41423 13.6563C9.12134 13.9492 8.64647 13.9492 8.35357 13.6563L5.5911 10.8938Z"
        fill="#8990FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4698 14.7119C10.7627 14.419 11.2376 14.419 11.5304 14.7119L14.2929 17.4743C15.1716 18.353 16.5962 18.353 17.4749 17.4743C18.3536 16.5956 18.3536 15.171 17.4749 14.2923L14.7124 11.5299C14.4195 11.237 14.4195 10.7621 14.7124 10.4692C15.0053 10.1763 15.4802 10.1763 15.7731 10.4692L18.5356 13.2317C20 14.6962 20 17.0705 18.5356 18.535C17.0711 19.9995 14.6967 19.9995 13.2323 18.535L10.4698 15.7725C10.1769 15.4796 10.1769 15.0048 10.4698 14.7119Z"
        fill="#8990FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
        fill="#8990FF"
      />
    </svg>
  );
}
