import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../API/RequestHandler";
import getAPIPath, { APIPaths, ServerResponse } from "../API/APIPaths";
import Activity, {
  AttendeeResponse,
  IActivity,
  IComment,
  IDoerResponse,
  Invitation,
} from "../types/activity";
import { queries } from "../utils/queries";
import { auth } from "../firebase";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export const useActivity = (activityId: string) => {
  const [isUser, setIsUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    data,
    refetch,
    status,
  } = useQuery<AxiosResponse<ServerResponse<Activity>>, Error>({
    queryKey: [queries.GET_ACTIVITIES, activityId],
    queryFn: () =>
      httpClient.get<ServerResponse<Activity>>(
        getAPIPath(APIPaths.GET_ACTIVITY).replace("{{id}}", activityId),
        {}
      ),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!activityId && isUser,
    select: (data: any) => {
      return data;
    },
  });

  useEffect(() => {
    auth?.onAuthStateChanged(async () => {
      setIsUser(true);
      setIsLoading(false);
    });
  }, []);

  return { data, isLoading, refetch, status };
};

export const useAttendees = (id: string) => {
  const { data, isLoading, refetch, status } = useQuery({
    queryKey: [queries.GET_ATTENDEES, id],
    queryFn: () =>
      httpClient.get<ServerResponse<AttendeeResponse[]>>(
        getAPIPath(APIPaths.GET_ATTENDEES).replace("{{id}}", id),
        {}
      ),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
    select: (data: any) => {
      return data?.data?.result;
    },
  });

  return { data, isLoading, refetch, status };
};

export const useDoer = (id: string) => {
  const [isUser, setIsUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data, refetch } = useQuery({
    queryKey: [queries.GET_DOER, id],
    queryFn: () =>
      httpClient.get<IDoerResponse>(
        getAPIPath(APIPaths.GET_DOER).replace(
          "{{id}}",
          auth.currentUser?.uid || ""
        )
      ),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id && isUser,
    select: (data) => {
      return data?.data?.result;
    },
  });

  useEffect(() => {
    auth?.onAuthStateChanged(async () => {
      setIsUser(true);
      setIsLoading(false);
    });
  }, []);

  return { data, isLoading, refetch };
};

export const useActivityDoersEmotion = (data: {
  activityId: string;
  emotion: string;
}) => {
  return useMutation({
    mutationFn: () =>
      httpClient.post(getAPIPath(APIPaths.GET_EMOTION_USERS), data),
  });
};

export const useSetActivityDoerEmotion = (data: {
  activityId: string;
  emotion: string;
  comment: string;
}) => {
  return useMutation({
    mutationFn: () =>
      httpClient.post(getAPIPath(APIPaths.SET_DOER_EMOTION), data),
  });
};

export const useGetEmotionUsers = () => {
  return useMutation({
    mutationFn: (data: { activityId: string; emotion: string }) =>
      httpClient.post(getAPIPath(APIPaths.GET_EMOTION_USERS), data),
  });
};

export const useGetInvitation = () => {
  return useMutation({
    mutationFn: (activityId: string) =>
      httpClient.post(getAPIPath(APIPaths.GET_INVITATIONS), { activityId }),
  });
};

export const useSendInvitation = () => {
  return useMutation({
    mutationFn: (activityId: {
      activityId: string;
      to: Invitation[];
      customMessage: string;
      timeZone: string;
      isSetCalendar: boolean;
    }) => httpClient.post(getAPIPath(APIPaths.SEND_INVITATIONS), activityId),
  });
};

export const useComments = (streamId: string) => {
  const { data, isLoading, refetch, status } = useQuery({
    queryKey: [queries.GET_INVITATIONS],
    queryFn: () =>
      httpClient.post<ServerResponse<IComment[]>>(
        getAPIPath(APIPaths.GET_ALL_COMMENTS),
        {
          activity_id: streamId,
          kind: "comment",
          limit: 1000,
        }
      ),

    retry: false,
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data?.result;
    },
  });

  return { data, isLoading, refetch, status };
};

export const useUpCommingActivities = (activityStatus: string) => {
  const { refetch, data, isPending, status } = useQuery({
    queryKey: [queries.GET_UPCOMING_ACTIVITIES],
    queryFn: () =>
      httpClient.post<ServerResponse<IActivity[]>>(
        getAPIPath(APIPaths.GET_UPCOMING_ACTIVITIES)
      ),
    staleTime: 1000 * 60 * 5,
    enabled: !!auth?.currentUser && activityStatus === "upcoming",
  });

  useEffect(() => {
    auth?.onAuthStateChanged(async () => {
      refetch();
    });
  }, []);
  return { refetch, data, isPending, status };
};

export const usePastActivities = (activityStatus: string) => {
  const { data, isPending, status } = useQuery({
    queryKey: [queries.GET_PAST_ACTIVITY],
    queryFn: () =>
      httpClient.post<ServerResponse<IActivity[]>>(
        getAPIPath(APIPaths.GET_PAST_ACTIVITIES)
      ),
    staleTime: 1000 * 60 * 5,
    enabled: activityStatus === "past" && !!auth?.currentUser,
  });

  return { data, isPending, status };
};
