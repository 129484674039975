export function LockMemberIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 14H12C10.8954 14 10 14.8954 10 16V24C10 25.1046 10.8954 26 12 26H20C21.1046 26 22 25.1046 22 24V16C22 14.8954 21.1046 14 20 14ZM12 12C9.79086 12 8 13.7909 8 16V24C8 26.2091 9.79086 28 12 28H20C22.2091 28 24 26.2091 24 24V16C24 13.7909 22.2091 12 20 12H12Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3332 7.66699C14.4127 7.66699 13.6665 8.41318 13.6665 9.33366V12.667H11.6665V9.33366C11.6665 7.30861 13.3081 5.66699 15.3332 5.66699H16.6665C18.6915 5.66699 20.3332 7.30861 20.3332 9.33366V12.667H18.3332V9.33366C18.3332 8.41318 17.587 7.66699 16.6665 7.66699H15.3332Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 19C16.5523 19 17 19.4477 17 20V23.3333C17 23.8856 16.5523 24.3333 16 24.3333C15.4477 24.3333 15 23.8856 15 23.3333V20C15 19.4477 15.4477 19 16 19Z"
        fill="#464646"
      />
    </svg>
  );
}
