export enum RouteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  VERIFIED = "VERIFIED",
}

export const routes = {
  promotion: {
    creationIndex: {
      pathname: "/promotion",
      routeType: RouteType.PUBLIC,
    },
    createActivity: {
      pathname: "/promotion/create",
      routeType: RouteType.VERIFIED,
    },
    preview: {
      pathname: "/promotion/preview",
      routeType: RouteType.PRIVATE,
    },
    edit: {
      pathname: "/promotion/edit",
      routeType: RouteType.PUBLIC,
    },
  },

  activity: {
    edit: { pathname: "/edit", routeType: RouteType.VERIFIED },
    main: { pathname: "/activity/", routeType: RouteType.PUBLIC },
    manual: {
      pathname: "/manual/create",
      routeType: RouteType.VERIFIED,
    },
    create: { pathname: "/create", routeType: RouteType.PUBLIC },
    profileSelection: {
      pathname: "/create/select",
      routeType: RouteType.VERIFIED,
    },
    aiCreate: {
      pathname: "/ai/create",
      routeType: RouteType.VERIFIED,
    },
    preview: { pathname: "/preview", routeType: RouteType.VERIFIED },
    previewV1: {
      pathname: "/preview/v1",
      routeType: RouteType.VERIFIED,
    },
    payment: { pathname: "/payment", routeType: RouteType.VERIFIED },
  },

  sponsor: {
    main: { pathname: "/sponsor", routeType: RouteType.PRIVATE },
    organization: {
      pathname: "/sponsor/organization",
      routeType: RouteType.PRIVATE,
    },
    organizationUpdate: {
      pathname: "/sponsor/organization/update",
      routeType: RouteType.PRIVATE,
    },
    profile: {
      pathname: "/sponsor/profile",
      routeType: RouteType.PRIVATE,
    },
    profileUpdate: {
      pathname: "/sponsor/profile/update",
      routeType: RouteType.PRIVATE,
    },
    explore: {
      pathname: "/sponsor/explore",
      routeType: RouteType.PRIVATE,
    },
    MyPastSponsored: {
      pathname: "/sponsor/MyPastSponsored",
      routeType: RouteType.PRIVATE,
    },
    MyUpComingSponsored: {
      pathname: "/sponsor/MyUpComingSponsored",
      routeType: RouteType.PRIVATE,
    },
    payment: {
      pathname: "/sponsor/payment",
      routeType: RouteType.PRIVATE,
    },
    congratulations: {
      pathname: "/sponsor/congratulations",
      routeType: RouteType.PRIVATE,
    },
    activity: {
      pathname: "/sponsor/activity",
      routeType: RouteType.PRIVATE,
    },
  },

  community: {
    main: { pathname: "/community", routeType: RouteType.PUBLIC },
    create: {
      pathname: "/community/create",
      routeType: RouteType.VERIFIED,
    },
    createSocials: {
      pathname: "/community/create/socials",
      routeType: RouteType.VERIFIED,
    },
    edit: {
      pathname: "/community/edit/:communityId",
      routeType: RouteType.VERIFIED,
    },
    communityDetails: {
      pathname: "/community/:communityId",
      routeType: RouteType.PUBLIC,
    },

    myCommunities: {
      pathname: "/community/my-communities",
      routeType: RouteType.VERIFIED,
    },
  },

  login: { pathname: "/login", routeType: RouteType.PUBLIC },

  discover: { pathname: "/explore", routeType: RouteType.PUBLIC },

  myActivities: {
    pathname: "/my-activities",
    routeType: RouteType.VERIFIED,
  },

  returnStripe: {
    pathname: "/returnStripe",
    routeType: RouteType.PRIVATE,
  },

  private: { pathname: "/private", routeType: RouteType.PRIVATE },
  resetPassword: { pathname: "/reset-password", routeType: RouteType.PUBLIC },

  notFound: { pathname: "*", routeType: RouteType.PUBLIC },
};
