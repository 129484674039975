import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import { routes } from "./routes";
import { Redirect } from "../utils/Redirect";
import AuthWrapper from "./AuthWrapper";
const NotFound = lazy(() => import("../pages/NotFound"));
const PromotionPreview = lazy(
  () => import("../pages/Promotion/PromotionPreview")
);
const CommunityLayout = lazy(
  () => import("../pages/Community/CommunityLayout")
);
const Spinner = lazy(() => import("../components/common/Spinner/Spinner"));
const ReturnStripe = lazy(() => import("../pages/ReturnStripe"));
const SponsorCongratulations = lazy(
  () => import("../pages/Sponsor/SponsorCongratulations")
);
const PromotionLayout = lazy(
  () => import("../pages/Promotion/PromotionLayout")
);
const ActivityLayout = lazy(
  () => import("../pages/ActivityDetails/ActivityLayout")
);
const Layout = lazy(() => import("../components/SponsorLayout"));
const PromotionEdit = lazy(() => import("../pages/Promotion/PromotionEdit"));
const PromotionCreate = lazy(
  () => import("../pages/Promotion/PromotionCreate")
);
const Discover = lazy(() => import("../pages/Discover"));
const MyActivities = lazy(() => import("../pages/MyActivities"));
const Login = lazy(() => import("../pages/Login"));
const EmailVerification = lazy(() => import("../pages/EmailVerification"));
const CommunityDetails = lazy(
  () => import("../pages/Community/CommunityDetails")
);
const CommunityExplore = lazy(() => import("../pages/CommunityExplore"));
const MyCommunities = lazy(() => import("../pages/MyCommunities"));

const CommunityCreate = lazy(
  () => import("../components/CreateCommunity/CreateCommunity")
);
const CreateCommunitySocials = lazy(
  () => import("../components/CreateCommunity/CreateCommunitySocials")
);
const CommunityEdit = lazy(
  () => import("../components/CreateCommunity/EditCommunity")
);
const SponsorActivityDetails = lazy(
  () => import("../pages/Sponsor/SponsorActivityDetails")
);
const SponsorPayment = lazy(() => import("../pages/Sponsor/SponsorPayment"));
const MyPastSponsored = lazy(() => import("../pages/Sponsor/MyPastSponsored"));
const MyUpComingSponsored = lazy(
  () => import("../pages/Sponsor/MyUpComingSponsored")
);
const SponsorExplore = lazy(() => import("../pages/Sponsor/SponsorExplore"));
const SponsorProfile = lazy(() => import("../pages/Sponsor/SponsorProfile"));
const SponsorUpdateProfile = lazy(
  () => import("../pages/Sponsor/SponsorUpdateProfile")
);
const SponsorOrganization = lazy(
  () => import("../pages/Sponsor/SponsorOrganization")
);
const SponsorUpdateOrganization = lazy(
  () => import("../pages/Sponsor/SponsorUpdateOrganization")
);
const EditActivity = lazy(() => import("../pages/EditActivity"));
const ActivityDetails = lazy(
  () => import("../pages/ActivityDetails/ActivityDetails")
);
const MainPageV2 = lazy(
  () => import("../components/CreateActivity/MainPageV2")
);
const IntroPage = lazy(
  () => import("../components/CreateActivity/MainPageV2/IntroPage")
);
const ProfileSelection = lazy(
  () => import("../components/Community/ProfileSelection/ProfileSelection")
);
const AIPage = lazy(() => import("../components/CreateActivity/AIPage"));
const Preview = lazy(() => import("../pages/Preview"));
const PublishActivity = lazy(() => import("../pages/PublishActivity"));
const Payment = lazy(() => import("../pages/Payment"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

const MainRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AuthWrapper />,
    children: [
      {
        index: true,
        element: <Redirect />,
      },
      {
        path: routes.returnStripe.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <div className=" font-interregular">
              <ReturnStripe />
            </div>
          </Suspense>
        ),
      },
      {
        path: routes.login.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <div className="p-2 font-interregular">
              <Login />
            </div>
          </Suspense>
        ),
      },
      {
        path: routes.community.main.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <div className=" font-interregular">
              <CommunityExplore />
            </div>
          </Suspense>
        ),
      },

      {
        path: routes.discover.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <Discover />
          </Suspense>
        ),
      },
      {
        path: routes.myActivities.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <MyActivities />
          </Suspense>
        ),
      },
      {
        path: routes.community.myCommunities.pathname,
        element: (
          <Suspense fallback={<Spinner />}>
            <MyCommunities />
          </Suspense>
        ),
      },
      {
        path: "",
        element: <ActivityLayout />,
        children: [
          {
            path: routes.resetPassword.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <ResetPassword />
              </Suspense>
            ),
          },
          {
            path: routes.private.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <EmailVerification />
              </Suspense>
            ),
          },
          {
            path: routes.activity.edit.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <EditActivity />
              </Suspense>
            ),
          },
          {
            path: routes.activity.main.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <ActivityDetails />
              </Suspense>
            ),
          },
          {
            path: routes.activity.manual.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <MainPageV2 />
              </Suspense>
            ),
          },
          {
            path: routes.activity.create.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <IntroPage />
              </Suspense>
            ),
          },
          {
            path: routes.activity.aiCreate.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <AIPage />
              </Suspense>
            ),
          },
          {
            path: routes.activity.profileSelection.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <ProfileSelection />
              </Suspense>
            ),
          },
          {
            path: routes.activity.preview.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <PublishActivity />
              </Suspense>
            ),
          },
          {
            path: routes.activity.previewV1.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <Preview />
              </Suspense>
            ),
          },
          {
            path: routes.activity.payment.pathname,
            element: (
              <div className="p-2 font-interregular">
                <Suspense fallback={<Spinner />}>
                  <Payment />
                </Suspense>
              </div>
            ),
          },
        ],
      },
      {
        path: "",
        element: <PromotionLayout />,
        children: [
          {
            path: routes.promotion.createActivity.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <PromotionCreate />
              </Suspense>
            ),
          },
          {
            path: routes.promotion.edit.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <PromotionEdit />
              </Suspense>
            ),
          },
          {
            path: routes.promotion.preview.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <PromotionPreview />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: routes.sponsor.main.pathname,
        element: <Layout />,
        children: [
          {
            path: routes.sponsor.organizationUpdate.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorUpdateOrganization />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.organization.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorOrganization />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.profileUpdate.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorUpdateProfile />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.profile.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorProfile />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.explore.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorExplore />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.MyUpComingSponsored.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <MyUpComingSponsored />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.MyPastSponsored.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <MyPastSponsored />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.payment.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorPayment />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.congratulations.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorCongratulations />
              </Suspense>
            ),
          },
          {
            path: routes.sponsor.activity.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <SponsorActivityDetails />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: routes.community.main.pathname,
        element: <CommunityLayout />,
        children: [
          {
            path: routes.community.communityDetails.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <CommunityDetails />
              </Suspense>
            ),
          },
          {
            path: routes.community.create.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <CommunityCreate />
              </Suspense>
            ),
          },
          {
            path: routes.community.createSocials.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <CreateCommunitySocials />
              </Suspense>
            ),
          },
          {
            path: routes.community.edit.pathname,
            element: (
              <Suspense fallback={<Spinner />}>
                <CommunityEdit />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<Spinner />}>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
]);

export default MainRoutes;
