import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loader } from "../components/SplashScreen";
import { routes, RouteType } from "./routes";
import { Modals } from "../components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material";

const Layout = ({ children }: React.PropsWithChildren) => {
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
      allVariants: {
        textTransform: "none",
        fontFamily: "InterMedium",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
        <Modals />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const AuthWrapper: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return (
      <div className="w-full h-full absolute left-0 top-0 flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  const flattenRoutes = Object.entries(routes)
    .flatMap(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        if ("pathname" in value && "routeType" in value) {
          //  top-level route
          return [value];
        } else {
          // nested route group
          return Object.values(value);
        }
      }
      return [];
    })
    .filter(
      (route): route is { pathname: string; routeType: RouteType } =>
        route &&
        typeof route === "object" &&
        "pathname" in route &&
        "routeType" in route
    );

  const currentRoute = flattenRoutes.find(
    (route) => route.pathname === location.pathname
  );

  if (!currentRoute) {
    console.log("Route not found for pathname:", location.pathname);
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  const { routeType } = currentRoute;

  // Handle PUBLIC routes
  if (routeType === RouteType.PUBLIC) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  // Handle PRIVATE routes
  if (routeType === RouteType.PRIVATE && !user) {
    const loginUrl = `/login?returnUrl=${encodeURIComponent(
      location.pathname
    )}`;
    return <Navigate to={loginUrl} replace />;
  }

  // Handle VERIFIED routes
  if (routeType === RouteType.VERIFIED) {
    if (!user) {
      const loginUrl = `/login?returnUrl=${encodeURIComponent(
        location.pathname
      )}`;
      return <Navigate to={loginUrl} replace />;
    }

    // Check if email is verified
    if (!user.emailVerified) {
      const privateUrl = `/private?returnUrl=${encodeURIComponent(
        location.pathname
      )}`;
      return <Navigate to={privateUrl} state={{ canGoBack: true }} replace />;
    }
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default AuthWrapper;
