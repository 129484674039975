export function VideoIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 5.66663H4C3.44772 5.66663 3 6.11434 3 6.66663V9.33329C3 9.88558 3.44772 10.3333 4 10.3333H7.33333C7.88562 10.3333 8.33333 9.88558 8.33333 9.33329V6.66663C8.33333 6.11434 7.88562 5.66663 7.33333 5.66663ZM4 4.66663C2.89543 4.66663 2 5.56206 2 6.66663V9.33329C2 10.4379 2.89543 11.3333 4 11.3333H7.33333C8.4379 11.3333 9.33333 10.4379 9.33333 9.33329V6.66663C9.33333 5.56206 8.4379 4.66663 7.33333 4.66663H4Z"
        fill="#8990FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.492 6.10233L11.4759 6.7276C11.1802 6.9096 11 7.232 11 7.57926V8.87431C11 9.26437 11.2268 9.61882 11.5809 9.78227L12.527 10.2189C12.7479 10.3208 13 10.1595 13 9.91625V6.38622C13 6.12545 12.7141 5.96566 12.492 6.10233ZM10.9518 5.87595C10.3603 6.23994 10 6.88474 10 7.57926V8.87431C10 9.65442 10.4536 10.3633 11.1619 10.6902L12.1079 11.1269C12.9914 11.5346 14 10.8893 14 9.91625V6.38622C14 5.34312 12.8562 4.70399 11.9679 5.25067L10.9518 5.87595Z"
        fill="#8990FF"
      />
    </svg>
  );
}
