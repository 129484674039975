import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "../../types";

export interface ModalState {
  modals: Modal[];
}

const initialState: ModalState = {
  modals: [],
};

const ModalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    newModal: (state, action: PayloadAction<Modal>) => {
      type WritableDraftState = typeof state.modals;
      const newModal = {
        ...action.payload,
        id: action.payload.id ?? `${Date.now()}-${state.modals.length}`,
      };
      state.modals.push(newModal as WritableDraftState[number]);
    },
    closeModal: (state, action: PayloadAction<string | undefined>) => {
      state.modals = state.modals.filter(
        (modal) => modal.id !== action.payload
      );
    },
  },
});

export const { closeModal, newModal } = ModalSlice.actions;
export default ModalSlice.reducer;
