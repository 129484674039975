import React from "react";

export const CreateIconOne = () => {
  return (
    <svg
      width="184"
      height="196"
      viewBox="0 0 184 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6877 178.274C17.114 162.77 0.707449 123.87 19.6707 92.3068C20.9151 90.6016 24.3932 86.758 28.3497 85.0254C31.6736 87.196 40.2478 90.4799 47.9531 86.2513C55.6584 82.0227 57.7033 75.8766 57.7627 73.3322C65.1926 75.2738 80.9864 83.9458 84.7226 103.101M35.3596 113.048C39.8417 112.443 51.042 114.479 59.9864 127.462"
        stroke="#2EB67D"
      />
      <path
        d="M58.7474 136.236C61.8174 127.679 74.4999 106.735 100.671 91.4137C103.043 90.0851 108.48 87.1158 111.257 85.8683L119.32 92.8993C109.94 112.671 81.2019 156.981 68.184 167.903C50.1743 183.013 37.1182 187.836 27.7554 178.288"
        stroke="#2EB67D"
      />
      <path
        d="M114.646 75.7207C115.685 79.5381 113.629 84.5391 112.471 86.5624L118.627 91.8571C121.317 89.69 130.6 88.3943 131.209 85.3586C131.818 82.323 127.223 78.2436 125.056 75.5533C123.322 73.4011 120.284 76.8517 118.982 78.846C118.289 76.9026 115.803 75.9527 114.646 75.7207Z"
        stroke="#2EB67D"
      />
      <path
        d="M30 85C31.3333 83.4444 33.8222 79.4848 33.1111 76.0909M58 73.9697C56.0741 74.2525 51.7778 74.0545 50 71"
        stroke="#2EB67D"
      />
      <path
        d="M47.2768 59C48.9843 60.7843 50.9196 64.6824 45 66"
        stroke="#2EB67D"
      />
      <path
        d="M28.996 43.6129C28.4191 44.557 26.7518 46.385 24.6976 46.1439M35.2615 39.5782C35.8747 39.3611 38.0176 38.6225 38.8679 36.8215M28.9965 18.5204C31.8759 8.0174 59.9788 -5.16251 52.8534 19.1521C45.7281 43.4668 22.8056 35.5145 22.4415 35.0382C25.0481 40.8945 21.6556 49.9787 19.6335 53.7888C17.2522 51.1971 12.0934 44.6212 10.5083 39.0516"
        stroke="#2EB67D"
      />
      <path
        d="M39 43L46 50.04C45.5882 50.92 44.1882 52.944 41.8824 54"
        stroke="#2EB67D"
      />
      <path
        d="M42.6694 58.7125C42.9598 59.2917 43.0552 59.8747 42.998 60.3367C42.9403 60.8029 42.741 61.086 42.5002 61.2068C42.2594 61.3275 41.9133 61.3178 41.5052 61.0852C41.1007 60.8547 40.6906 60.4295 40.4002 59.8503C40.1098 59.2712 40.0143 58.6882 40.0715 58.2262C40.1292 57.76 40.3285 57.4768 40.5693 57.3561C40.8101 57.2353 41.1562 57.245 41.5644 57.4776C41.9688 57.7081 42.3789 58.1333 42.6694 58.7125Z"
        stroke="#2EB67D"
      />
      <path d="M20 54C21.76 58.697 26.424 68.2727 31 69" stroke="#2EB67D" />
      <path
        d="M94 135C96.0912 142.239 99.6464 160.173 97.1369 174"
        stroke="#2EB67D"
      />
      <path
        d="M69 103C70.2715 105.205 72.8994 110.497 73.2385 114.025M83.8346 150.186C84.6823 154.89 86.2929 166.239 85.9538 174"
        stroke="#2EB67D"
      />
      <path
        d="M164.838 29.2828C167.69 36.5068 169.028 43.5131 168.901 48.99C168.837 51.7295 168.408 54.061 167.639 55.8399C166.871 57.6163 165.781 58.8071 164.407 59.3495C163.034 59.8919 161.424 59.7667 159.65 58.9941C157.873 58.2205 155.967 56.8112 154.049 54.854C150.214 50.9412 146.405 44.9111 143.552 37.687C140.7 30.463 139.363 23.4567 139.49 17.9798C139.553 15.2403 139.982 12.9089 140.751 11.1299C141.519 9.3535 142.609 8.16273 143.983 7.62032C145.357 7.07791 146.966 7.20309 148.74 7.97567C150.517 8.74934 152.423 10.1586 154.341 12.1158C158.176 16.0286 161.986 22.0587 164.838 29.2828Z"
        stroke="#2EB67D"
      />
      <path
        d="M116.429 76L113 70.8571L117.714 70L122 75.1429"
        stroke="#2EB67D"
      />
      <path d="M164 60.1757L100.387 74L96 67.3108L142.942 8" stroke="#2EB67D" />
      <path
        d="M151 21.25L167.28 1M154.96 33.4L176.96 24.4M158.92 46H184"
        stroke="#2EB67D"
      />
      <path d="M42 183L46 190" stroke="#2EB67D" />
      <path
        d="M21 19C16.3259 19.7207 7.42992 23.9297 9.23927 35"
        stroke="#2EB67D"
      />
    </svg>
  );
};
