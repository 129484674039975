import { Suspense } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import SplashScreen from "./components/SplashScreen";
import { Provider } from "react-redux";
import { store } from "./lib/store";
import "./animation.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MainRoutes from "./config/MainRoutes";
import { AuthProvider } from "./context/authContent";

function App() {
  const queryClient = new QueryClient();

  return (
    <Suspense fallback={<SplashScreen loading={true} />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <div className="font-interregular">
              <RouterProvider router={MainRoutes} />
            </div>
          </AuthProvider>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
