import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from "react";
import { auth } from "../firebase";
import { IDoer } from "../types/activity";
import { useDoer } from "../hooks/useActivity";

export interface AuthContextType {
  isLogin: boolean;
  signOut: () => void;
  doer: IDoer | undefined;
  getDoer: () => void;
  isCommunityStepPassed: boolean;
  communityId: string | null;
  setCommunity: (id: string) => void;
  resetCommunityStep: () => void;
  communityCover: string;
  setCommunityCover: (cover: string) => void;
  communityAvatar: string;
  setCommunityAvatar: (avatar: string) => void;
  communityDetails: string;
  setCommunityDetails: (details: string) => void;
  communityName: string;
  setCommunityName: (name: string) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data: doer, refetch: getDoer } = useDoer(
    auth?.currentUser?.uid || ""
  );
  const [isLogin, setIsLogin] = useState(false);
  const [isCommunityStepPassed, setIsCommunityStepPassed] = useState(false);
  const [communityId, setCommunityId] = useState<string | null>(null);
  const [communityCover, setCommunityCover] = useState<string>("");
  const [communityAvatar, setCommunityAvatar] = useState<string>("");
  const [communityDetails, setCommunityDetails] = useState<string>("");
  const [communityName, setCommunityName] = useState<string>("");

  useEffect(() => {
    auth?.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLogin(true);
        getDoer();
      } else {
        setIsLogin(false);
      }
    });
  }, [auth]);

  const signOut = () => {
    auth.signOut();
  };

  const setCommunity = (id: string) => {
    setCommunityId(id);
    setIsCommunityStepPassed(true);
  };
  const resetCommunityStep = () => {
    setIsCommunityStepPassed(false);
    setCommunityId(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        signOut,
        doer,
        getDoer,
        isCommunityStepPassed,
        communityId,
        setCommunity,
        resetCommunityStep,
        communityCover,
        setCommunityCover,
        communityAvatar,
        setCommunityAvatar,
        communityDetails,
        setCommunityDetails,
        communityName,
        setCommunityName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
