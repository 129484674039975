import axios from "axios";
import { authMiddleware } from "../../Middleware/authMiddleware";

const httpClient = axios.create();

httpClient.interceptors.request.use(authMiddleware.request);

httpClient.interceptors.response.use(
  authMiddleware.response.onSuccess,
  authMiddleware.response.onError
);

export default httpClient;
