export const queries = {
  GET_COMMUNITY: "get_community",
  GET_UP_ACTIVITY: "get_up_community",
  GET_PAST_ACTIVITY: "get_past_community",
  GET_COMMUNITY_MODERATORS: "get_community_moderators",
  GET_COMMUNITY_MEMBERS: "get_community_members",
  GET_COMMUNITY_POSTS: "get_community_posts",
  GET_ACTIVITIES: "get_activities",
  GET_INVITATIONS: "get_invitations",
  GET_ATTENDEES: "get_attendees",
  GET_DOER: "get_doer",
  GET_UPCOMING_ACTIVITIES: "get_upcomming_activities",
  GET_PAST_ACTIVITIES: "get_past_activities",
  GET_MY_COMMUNITIES: "get_my_communities",
  CREATE_COMMUNITY: "create_community",
};
