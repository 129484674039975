import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { auth } from "../firebase";
import { sendEmailVerification } from "firebase/auth";
import { useAuth } from "../context/authContent";

interface ErrorResponse {
  message: string;
  title: string;
  type: string;
}

export const authMiddleware = {
  request: async (request: InternalAxiosRequestConfig) => {
    const token = await auth.currentUser?.getIdToken(true);
    request.headers.set("Authorization", token);
    return request;
  },

  response: {
    onSuccess: (response: AxiosResponse): AxiosResponse => {
      return response;
    },

    onError: async (error: AxiosError): Promise<AxiosError> => {
      try {
        const errorData = error.response?.data as ErrorResponse;
        const status = error.response?.status;

        if (status === 401) {
          auth.signOut();
        } else if (status === 403) {
          if (errorData?.title === "UnVerified User") {
            const user = auth.currentUser;
            if (user) {
              await sendEmailVerification(user);
              window.location.href = "/private";
            }
          }
        }
      } catch (catchError: unknown) {}
      return Promise.reject(error);
    },
  },
};
